.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.text-white {
  color: #ffffff;
}

.text-headings {
  color: #ffffff;
  font-weight:400;
}

.text-default-color {
  color: #a1a5ab;
}

.text-values-white {
  color: #ffffff;
  font-weight:500;
}

.text-values-dark-grey {
  color: #101014;
  font-weight:500;
}

.text-large {
  font-size: 30px;
}

.background-light-grey {
  background-color: #242428;
}

.background-dark-grey {
  background-color: #101014;
}

.border-light-grey {
  border: 1px solid #242428;
}

.border-dark-grey {
  border: 1px solid #101014;
}

.overlay {
  position: absolute;
  /* top: 10px;
  left: 10px; */
  background-color: rgba(255,255,255,0); /* semi-transparent white */
  padding: 5px;
  border-radius: 5px;
  z-index: 10;
  display: none; /* initially hidden */
}

.dark-mode-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer; /* Change to hand icon when hovered */
  transition: all 0.1s;
}

/* Button hover effect */
.dark-mode-button:hover {
  background-color: #45a049; /* Darker shade of green for hover state */
}

/* Button focus effect */
.dark-mode-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

